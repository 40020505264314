<template>
    <div class="container" style="position:relative">
        <div class="list-group-item" :class="{'print':print}">
            <div class="title"  @mouseenter="handleTitleMouseenter" @mouseleave="handleTitleMouseleave">
                <div class="content">
                    <div class="name" @click="handleTitlePopShow">{{ item.name }}</div>
                    <div class="time" @click="handleTitlePopShow">{{item.beginTime}}-{{item.endTime}}</div>
                    <i class="el-icon-d-caret handle" v-show="titleHandleShow"></i>
                    <i class="el-icon-circle-close" v-show="titleHandleShow" @click="handleDeleteSelf"></i>
                </div>
            </div>
            <div class="itemList" :class="[weekLen === 7 ? 'fullWeek' : 'workWeek']">
                <div v-for="(day, index) of displayData" :key="`${day.key}${index}`"
                    :class="['item',
                        day.size ? ('day'+day.size) : 'hide',
                        editLock ? '' : 'item-edit',
                        day.key ? '' : 'item-empty',] "
                    @contextmenu.prevent="onContextmenu($event, day)"
                    @mouseenter="handleMouseenter(day, index)"
                    @mouseleave="handleMouseleave(day, index)">
                    <schedule-res v-if="day.key" :original-data='originalData'
                        :res="day.key" :size="day.size"
                        :index="index" :edit-lock="editLock"
                        :color='bgColor[index]' :period='daySelected[index]'
                        :print='print' :show-style="showStyle"
                        :theme-activite='item.name === "主题活动" ? true : false'
                        @handleEditInput="handleEditInput"
                        @handleColorPick="handleColorPick"
                        @handleDaySelect="handleDaySelect"
                        @handlePanel='handlePanel'>
                    </schedule-res>
                    <div class="add-res-btn" v-if="day.addResBtnShow">
                        <div class="add-res-btn-item" @click="handleAddResShow(day, index)">
                            <i class="el-icon-plus"></i> 添加资源</div>
                        <div class="add-res-btn-item" @click="handleAddResShowByInput(day, index)">
                            <i class="iconfont icon-edit"></i> 添加文字</div>
                    </div>
                    <div class="right-border"></div>
                </div>
            </div>
        </div>

        <el-dialog title="添加资源" class="schedule-item-addByRes" @closed="handleResPopClose"
            :close-on-click-modal="false" :visible.sync="resPopShow" >
            <div class="add-res-form">
                <div class="add-res-form-left">
                    <ResDirectory :canEdit='false' :width="'auto'" :styleType="'small'"
                        @handlePathLabel="handlePathLabel"></ResDirectory>
                </div>
                <div class="add-res-form-right">
                    <el-input placeholder="请输入内容" class="input-with-select"
                        v-model="resourcesForm.name" @keyup.enter.native="handleSearchRes(1)" clearable @clear="handleSearchRes(1)">
                        <el-button slot="append" icon="el-icon-search" size="mini" @click="handleSearchRes(1)"></el-button>
                    </el-input>
                    <div class="select-group">
                        <div class="select-group-item" v-for="(item, index) of resourceList" :key="index"
                            @click="handleSelectRes(item, index)">
                            <div class="left">
                                <div :class="['icon', index === resourcesForm.resIndex ? 'active' : '']">
                                    <span v-if="index === resourcesForm.resIndex">✓</span>
                                </div>
                                <div class="name">{{item.name}}</div>
                            </div>
                            <div class="res">
                                <img class="res-img" v-if="item.files_type.includes(filesType.AUDIO)" :src="require('@/assets/images/res/music.png')"/>
                                <img class="res-img" v-if="item.files_type.includes(filesType.PPT)" :src="require('@/assets/images/res/ppt.png')"/>
                                <img class="res-img" v-if="item.files_type.includes(filesType.DOCUMENT)" :src="require('@/assets/images/res/text.png')"/>
                                <img class="res-img" v-if="item.files_type.includes(filesType.VIDEO)" :src="require('@/assets/images/res/video.png')"/>
                                <img class="res-img" v-if="item.files_type.includes(filesType.IMAGE)" :src="require('@/assets/images/res/image.png')" />
                                <img class="res-img" v-if="item.files_type.includes(filesType.EXCLE)" :src="require('@/assets/images/res/excle.png')" />
                                <img class="res-img" v-if="item.files_type.includes(filesType.PACKAGE)" :src="require('@/assets/images/res/file.png')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="left">
                    <el-pagination
                        layout="prev, pager, next"
                        background
                        :page-size="8"
                        :current-page="currentResPage"
                        :pager-count="5"
                        @current-change="handleSearchRes"
                        :total="resTotal">
                    </el-pagination>
                </div>
                <div class="right">
                    <el-button type="primary" @click="handleAddRes">保存</el-button>
                    <el-button type="info" plain @click="resPopShow = false">关闭</el-button>
                </div>
            </span>
        </el-dialog>
        <el-dialog :title="editLock? '查看文字' : '添加文字'" class="schedule-item-addByInput" @closed="handleResPopClose"
            :visible.sync="resPopInputShow" :close-on-click-modal="editLock ? true : false" >
            <div class="add-res-form">
                <span class="add-res-form-content" v-if='editLock'>
                    {{resPopInputVal}}
                </span>
                <el-input v-else placeholder="请输入自定义资源内容" type="textarea"
                    style="white-space: pre-wrap;" v-model="resPopInputVal" :autosize="{minRows: 5}">
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleAddResInpput">{{ editLock? '确定' : '保存'}}</el-button>
                <el-button type="info" plain @click="resPopInputShow = false">关闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="修改日程" class="schedule-item-titleSetting"
            :visible.sync="titlePopShow" :close-on-click-modal="false" >
            <div class="setting-item">
                <span class="left">日程名称：</span>
                <el-input class="right" v-model="titleItem.name" placeholder="请输入内容"></el-input>
            </div>
            <div class="setting-item">
                <span class="left">日程时间：</span>
                <div class="time-pick-group right">
                    <el-time-picker size="mini" placeholder="请选择开始时间"
                        value-format="HH:mm" v-model="titleItem.beginTime">
                    </el-time-picker>
                    <div class="split-line">-</div>
                    <el-time-picker size="mini" placeholder="请选择结束时间"
                        value-format="HH:mm" v-model="titleItem.endTime">
                    </el-time-picker>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display:flex;justify-content: center;">
                <el-button type="primary" @click="handleItemTitle">保存</el-button>
                <el-button type="info" plain @click="titlePopShow = false">关闭</el-button>
            </span>
        </el-dialog>
        <transition name="fade">  <!-- 设置面板 -->
            <div class="editPanel" :style="editPanelPosition"
                v-if='editPanel' ref='editPanel' @click.stop>
                <div class="editPanel-header">
                    <span class="title">设置</span>
                </div>
                <div class="color-pick" >
                    <div class="color-pick-header">
                        <span class="title">选择背景颜色</span>
                        <span class="color-value">
                            {{activeColor}}
                        </span>
                    </div>
                    <div class="color-pick-predefine">
                        <div class="predefine__colors">
                            <div v-for="(item, index) in predefineBGColors" :key="index"
                                class="predefine__color-selector"
                                :class="item.selected ? 'color-selected' : '' "
                                @click="handleColorChange(index,item.color)">
                                <div class="predefine__color-selector-inner" :style="`background-color: ${item.color} `">
                                    <i class="el-icon-check" v-if="item.selected"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="day-pick" >
                    <div class="day-pick-header">
                        <span class="title">选择周期</span>
                    </div>
                    <div class="day-pick-predefine">
                        <el-checkbox-group v-model="daySelected[editPanelIndex]" class="predefine__days"  >
                            <el-checkbox v-for="item in weekList" :key="item.index" @change='handleDayChange(item.index)'
                                class="predefine__day-selector"
                                :label="item.label" name="type" :disabled="item.disabled">
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="editPanel-footer">
                    <div class="left">
                        <el-button type="info" @click="handleDeleteRes" size="mini" round>删除</el-button>
                    </div>
                    <div class="right">
                        <el-button @click="handelCancelEdit" size="mini" round>取消</el-button>
                        <el-button type="primary" @click="handleConfirmEdit" size="mini" round>确定</el-button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Core from '@/core';
import _ from '@kaobeimao/core';

import { mapState, mapMutations } from 'vuex';
import * as types from '@/store/mutation-types';

import { deepCopy } from '@/core/utils';

const WEEK = ['周一', '周二', '周三', '周四', '周五','周六', '周日'];
const WEEK_MAP = {'周一':0, '周二':1, '周三':2, '周四':3, '周五':4,'周六':5, '周日':6};

export default {
    components: {
        ScheduleRes: () => import('./ScheduleRes'),
        ResDirectory: () => import('@/components/ResDirectory'),
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        itemIndex: {
            type: Number
        },
        resourceList: {
            type: Array
        },
        resTotal: {
        },
        currentResPage:{
            type: Number
        },
        currentResName: {
            type: String
        },
        editLock: {
            type: Boolean
        },
        weekLen:{
            type: Number
        },
        showStyle: {
            default: 1
        },
        print: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            displayData: '',
            value1: '',
            titleHandleShow: false,
            titlePopShow: false,
            resPopShow: false,
            resPopInputShow: false,
            resPopInputVal: '',
            resBgColor: [],
            resourcesForm: {
                name: '',
                index: '',
                item: '',
                resIndex: ''
            },
            onContextmenu(event, day) {
                const canPaste = this.resourceClipboard && !day.key
                if (!this.editLock) {
                    this.$contextmenu({
                        items: [
                            {
                                label: '复制资源',
                                divided: true ,
                                disabled: !day.key,
                                icon: 'el-icon-copy-document',
                                onClick: () => {
                                    this.setResourceClipboard(day)
                                }
                            },
                            {
                                label: '粘贴资源',
                                disabled: !canPaste,
                                icon: 'iconfont icon-check-copy' ,
                                onClick: () => {
                                    this.$set(day, 'key', this.resourceClipboard.key);
                                }
                            }
                        ],
                        event,
                        customClass: 'class-a',
                        zIndex: 3,
                        minWidth: 150
                    });
                }
                return false;
            },
            filesType: Core.Const.UPLOAD_FILE_SUFFIX,
            originalData: [],
            editPanel: false,
            editPanelPosition: {},
            editPanelIndex: 0,
            bgColor: [],
            activeColor: '',
            selectedColorIndex: 0,
            predefineBGColors: deepCopy(Core.Const.PREDEFINE_COLORS.WEEKPLAN_BGCOLORS),
            COLOR_MAP : Core.Const.PREDEFINE_COLORS.WEEKPLAN_BGCOLORS_MAP,
            initColor: '',
            daySelected: [],
            weekList: [
                { index: 0, label: '周一', disabled: false,},
                { index: 1, label: '周二', disabled: false,},
                { index: 2, label: '周三', disabled: false,},
                { index: 3, label: '周四', disabled: false,},
                { index: 4, label: '周五', disabled: false,},
                { index: 5, label: '周六', disabled: false,},
                { index: 6, label: '周日', disabled: false,},
            ],
            divieData: [],
            divieItem: {},
            titleItem: {}
        };
    },
    computed: {
        ...mapState(['resourceClipboard']),
    },
    watch: {
        displayData: {
            handler: function(newVal, oldVal) {
                const val = this.handleOutputData(newVal);
                this.setScheduleData({
                    index: this.itemIndex,
                    val
                });
            },
            deep: true
        },
    },
    mounted() {
        this.handleMerge();
        this.initBgColorSelector();
        this.initDaySelector();
        window.addEventListener('click', this.closeEditPanel);
    },
    methods: {
        ...mapMutations({
            setScheduleData: types.SET_SCHEDULE_DATA,
            setResourceClipboard: types.SET_RESOURCE_CLIPBOARD,
        }),
        handleMerge() { //
            this.displayData = this.merge(this.item.week);
        },

        handleDeleteSelf() {
            this.$emit('handleDeleteSelf', this.itemIndex);
        },
        handleTitlePopShow() {  // 显示修改行标题的弹框
            if (this.editLock) {
                return;
            }
            this.titleItem = deepCopy(this.item)

            this.titlePopShow = true;
        },
        handleItemTitle() { // 确认修改行标题
            if (!this.titleItem.name) {
                return this.$message.warning('日程标题不可为空')
            } else if (!this.titleItem.beginTime) {
                return this.$message.warning('起始时间不可为空')
            } else if (!this.titleItem.endTime) {
                return this.$message.warning('结束时间不可为空')
            }

            this.item.name = this.titleItem.name
            this.item.beginTime = this.titleItem.beginTime
            this.item.endTime = this.titleItem.endTime

            this.titlePopShow = false
        },
        handleSearchRes(page = 1) {
            this.$emit('handleSearchRes', {
                name: this.resourcesForm.name,
                page: page
            });
        },
        handleEditInput(index, content) { // 接受手动输入
            this.resPopInputVal = content
            this.handleAddResShowByInput('', index);
        },
        handleAddResShowByInput(day, index) {
            this.resourcesForm.index = index;
            this.resPopInputShow = true;
        },
        handleAddResInpput() {
            if (_.U.isEmpty(this.resPopInputVal)) {
                this.$message.warning('请输入自定义资源');
                return;
            }
            this.$set(
                this.displayData[this.resourcesForm.index],
                'key',
                {
                    content: this.resPopInputVal
                }
            );
            this.resPopInputShow = false;
        },
        handleAddResShow(day, index) {
            this.resourcesForm.index = index;
            this.resourcesForm.name = this.currentResName;
            this.resPopShow = true;
        },
        handleSelectRes(item, index) {
            this.resourcesForm.item = item;
            this.resourcesForm.resIndex = index;
        },
        handleResPopClose() {
            // this.resourcesForm.name = '';
            this.resourcesForm.index = '';
            this.resourcesForm.item = '';
            this.resourcesForm.resIndex = '';

            this.resPopInputVal = '';
        },
        handleAddRes() { // 由资源库添加课程
            if (_.U.isEmpty(this.resourcesForm.item)) {
                this.$message.warning('请选择资源');
                return;
            }
            this.$set(
                this.displayData[this.resourcesForm.index],
                'key',
                this.resourcesForm.item
            );
            this.resPopShow = false;
        },
        handlePathLabel(pathLabelArr, activeDirId) {
            this.$emit('handleResDirectory', activeDirId);
        },
        handleMouseenter(day, index) { // 鼠标移入格子
            if (this.editLock) {
                return;
            }
            if (day.size < 7) {
                for (let i = index + 1; i < this.displayData.length; i++) {
                    const e = this.displayData[i];
                    if (e.size) {
                        // this.$set(day, 'showRightIcon', true);
                        break;
                    }
                }
            }
            if (day.size > 1) {
                // this.$set(day, 'showLeftIcon', true);
            }
            if (!day.key) {
                this.$set(day, 'addResBtnShow', true);
            }
        },
        handleMouseleave(day) {  // 鼠标移出格子
            // this._hideRight(day);
            // this._hideLeft(day);
            this.$set(day, 'addResBtnShow', false);
        },
        /*
            // _hideRight(day) { //
            //     this.$set(day, 'showRightIcon', false);
            // },
            // _hideLeft(day) { //
            //     this.$set(day, 'showLeftIcon', false);
            // },
            // hanleMergeNextEmptyDay(day, index) { //
            //     const size = day.size + this.displayData[index + 1].size;
            //     this.$set(day, 'size', size);
            //     this.$delete(this.displayData, index + 1);
            //     if (day.size === 7) {
            //         this._hideRight(day);
            //     }
            //     if (day.size > 1) {
            //         this.$set(day, 'showLeftIcon', true);
            //     }
            // },
        */
        handleSplit(day, index) { //
            this.$set(day, 'size', day.size - 1);
            this.displayData.splice(index + 1, 0, {
                key: '',
                size: 1
            });
            if (day.size === 1) {
                this._hideLeft(day);
            }
        },
        merge(origin, keyName = 'key', mark = 'name') { // 合并
            let result = [];
            // 反向搜索可以合并的元素ß
            let find = function(data, key) {
                for (let i = data.length - 1; i >= 0; i--) {
                    let item = data[i];
                    if (key && item.key.content && item.key.content === key.content) {
                        return i;
                    }
                    if (key && item.key[mark] && item.key[mark] === key[mark]) {
                        // TODO:
                        return i;
                    }
                }
                return -1;
            };
            for (let i = 0; i < origin.length; i++) {
                const name = origin[i][keyName];
                let findIndex = find(result, name);
                // 找到可以合并的
                if (findIndex >= 0) {
                    let mergeItem = result[findIndex];
                    // 坐标连续，可以合并
                    if (mergeItem.end === i) {
                        mergeItem.end = i + 1;
                        continue;
                    }
                }
                // 不可以合并的都作为独立元素添加
                result.push({ key: name, begin: i, end: i + 1 });
            }
            return result.map((item, index) => {
                return { [keyName]: item.key, size: item.end - item.begin };
            });
        },
        handleOutputData(outputData, keyName = 'key') { // 拆分
            let originalData = [];
            for (let i = 0; i < outputData.length; i++) {
                const element = outputData[i];
                for (let j = 0; j < element.size; j++) {
                    originalData.push({
                        [keyName]: element[keyName]
                    });
                }
            }
            return originalData;
        },
        handleTitleMouseenter() { // 鼠标移入标题
            if (this.editLock) {
                return;
            }
            this.titleHandleShow = true;
        },
        handleTitleMouseleave() { // 鼠标移出标题
            this.titleHandleShow = false;
        },
        handlePanel(index, isShow, position) { // 接收面板属性
            this.editPanel = true
            this.editPanelPosition = position
            this.editPanelIndex = index
        },
        handleDeleteRes() {  // 删除资源
            this.editPanel = false
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let size = this.displayData[this.editPanelIndex].size
                    if (size > 1) {
                        this.displayData.splice(this.editPanelIndex,1,{key: "", size: 1})
                        for (let i = 0; i < size-1; i++) {
                            this.displayData.splice(this.editPanelIndex,0,{key: "", size: 1})
                        }
                    } else {
                        this.$set(this.displayData[this.editPanelIndex], 'key', '');
                    }
                })
            this.initDaySelector()
            this.initBgColorSelector()
        },
        handelCancelEdit() { // 取消设置
            this.bgColor[this.editPanelIndex] = this.initColor
            this.editPanel = false
            this.initDaySelector()
            this.initBgColorSelector()
        },
        handleConfirmEdit(){ // 确定设置
            for (let i = 0; i < this.displayData.length; i++) {
                if (this.bgColor[i]) {
                    this.displayData[i].key.color = this.bgColor[i];
                }
            }
            this.displayData = this.merge(this.divieData)
            this.initDaySelector()
            this.initBgColorSelector()
            this.editPanel = false
        },
        handleColorPick(index,initColor){ // 选颜色
            this.initBgColorSelector()
            this.initColor = initColor
            let color = this.bgColor[index] || initColor
            this.activeColor = color
            let colorIndex = this.COLOR_MAP[color]

            this.predefineBGColors[this.selectedColorIndex].selected = false
            this.selectedColorIndex = colorIndex
            this.predefineBGColors[this.selectedColorIndex].selected = true
        },
        initBgColorSelector() { // 初始化背景颜色
            this.displayData.forEach((element,i) => {
                this.bgColor[i] = element.key.color
            });
        },
        handleColorChange(colorIndex,color) { // 改变选择颜色
            this.predefineBGColors[this.selectedColorIndex].selected = false
            this.predefineBGColors[colorIndex].selected = true
            this.selectedColorIndex = colorIndex
            this.bgColor[this.editPanelIndex] = color
            this.activeColor = color
        },
        handleDaySelect(index) { // 选周期
            this.initDaySelector()
            this.initDaySelected = deepCopy(this.daySelected[index])

            // 设定不能选的
            let other = []
            for (let i = 0; i < this.displayData.length; i++) {
                if(i !== index && this.daySelected[i].length !== 0) {
                    other.push(...this.daySelected[i])
                }
            }
            this.weekList = this.$options.data().weekList
            if (this.weekLen === 5) {
                this.weekList[5].disabled = true
                this.weekList[6].disabled = true
            }
            for (let i = 0; i < other.length; i++) {
                let weekIndex = WEEK_MAP[other[i]]
                this.weekList[weekIndex].disabled = true
            }

            this.divieData = []
            this.divieData = this.handleOutputData(this.displayData)
            for (let i = 0; i < this.divieData.length; i++) {
                this.divieData[i].size = 1
            }

            // 复制目标
            this.divieItem = deepCopy(this.displayData[this.editPanelIndex]);
            this.divieItem.size = 1
        },
        initDaySelector(){ // 初始化周期选项
            let week =  ['周一', '周二', '周三', '周四', '周五','周六', '周日'];
            this.daySelected = []
            this.displayData.forEach((element,i) => {
                this.daySelected[i] = week.splice(0,element.size)
                if (element.key === '') {
                    this.daySelected[i] = []
                }
            });
        },
        handleDayChange(weekIndex){ // 选择周期改变
            if (this.daySelected[this.editPanelIndex].includes(WEEK[weekIndex])) { // 增加
                this.divieData[weekIndex] = deepCopy(this.divieItem)
            } else { // 减少
                this.divieData[weekIndex] = {key: "", size: 1}
            }
        },
        closeEditPanel(){ // 点击空白处关闭窗口
            if (this.editPanel === false) {
                return
            }
            let editPanelDom = this.$refs['editPanel'];
            if(!editPanelDom.contains(event.target)){
                this.editPanel = false
                this.handelCancelEdit()
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeEditPanel);
    },
};
</script>

<style lang="scss" scoped>
.container {
    .list-group-item {
        display: flex;
        width: 100%;
        text-align: center;
        color: #000;
        height: 80px;
        .title {
            width: 240px;
            background: rgba(247, 249, 250, 1);
            border-right: 1px solid rgba(220, 227, 232, 1);
            border-left: 1px solid rgba(220, 227, 232, 1);
            @include ftb;
            .content {
                width: 100%;
                height: 100%;
                @include flex(column, center, center);
                text-align: center;
                position: relative;
                .name {
                    font-size: 16px;
                    font-weight: 500;
                    color: rgba(63, 69, 73, 1);
                }
                .time {
                    font-size: 14px;
                    color: #8c929b;
                }
                .el-icon-d-caret {
                    font-size: 20px;
                    cursor: move;
                    position: absolute;
                    top: 30px;
                    left: 10px;
                }
                .el-icon-circle-close {
                    font-size: 16px;
                    cursor: pointer;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    &:hover {
                        color: #e38c96;
                    }
                }
            }
        }
        .itemList{
            width: calc(100% - 240px);
            display: flex;
            .item {
                text-align: center;
                .right-border {
                    display: inline-block;
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    top: 0px;
                    right: 0px;
                    background-color: rgba(220, 227, 232, 1);
                }
                background: rgba(255, 255, 255, 1);
                position: relative;
                box-sizing: border-box;
                .add-res-btn {
                    box-sizing: border-box;
                    // margin-top: 24px;
                    color: #1D517E;
                    height: 100%;
                    @include flex(column, center, center);
                    .add-res-btn-item {
                        cursor: pointer;
                        span {
                            font-size: 18px;
                        }
                        &:last-of-type {
                            margin-top: 10px;
                        }
                    }
                }
                &.hide {
                    display: none;
                }
                /*
                    .to-right {
                        position: absolute;
                        right: 0;
                        top: 31px;
                        color: #1d517e;
                        cursor: pointer;
                        font-size: 18px;
                    }
                    .to-left {
                        position: absolute;
                        left: 0;
                        top: 31px;
                        color: #1d517e;
                        cursor: pointer;
                        font-size: 18px;
                    }
                */
                &-edit{
                    &.item-empty:hover{
                        // transition: transform 0.2s ease-in;
                        // transform: scale(1.01);
                        z-index:2;
                        border-color:rgba(237, 183, 189, 1);
                        border-radius: 2px;
                    }
                }
            }
            &.fullWeek{
                .item.day1{width: calc(100% / 7);}
                .item.day2{width: calc(100% / 7 * 2);}
                .item.day3{width: calc(100% / 7 * 3);}
                .item.day4{width: calc(100% / 7 * 4);}
                .item.day5{width: calc(100% / 7 * 5);}
                .item.day6{width: calc(100% / 7 * 6);}
                .item.day7{width: 100%;}
            }
            &.workWeek{
                .item.day1{width: 20%;}
                .item.day2{width: 40%;}
                .item.day3{width: 60%;}
                .item.day4{width: 80%;}
                .item.day5{width: 100%;}
                // .item.day2{width: calc(40% + 1px);}
            }
        }
    }
    .list-group-item.print {
        height: auto;
        .title {
            width: 150px;
            background-color: transparent;
            border-right: $print-border;
            border-left: $print-border;
            .content {
                .name {
                    height: 22px;
                    font-size: 16px;
                    // font-family: STSongti-SC-Bold, STSongti-SC;
                    font-family: $SongTi;
                    font-weight: bold;
                    color: #000000;
                    line-height: 22px;
                }
                .time {
                    height: 20px;
                    font-size: 14px;
                    // font-family: STSongti-SC-Regular, STSongti-SC;
                    font-family: $SongTi;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.7);
                    line-height: 20px;
                }
            }
        }
        .itemList {
            width: calc(100% - 150px);
            .item {
                // border-left: 1px solid #000000;
                // background: #ffffff;
                .right-border {
                    background-color: #000000;
                }
            }
        }
    }
    .schedule-item-addByRes{
        .add-res-form {
            @include flex(row, space-between, flex-start);
            position: relative;
            height: 100%;
            &::after{
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                left: 30%;
                top: 0;
                background-color: #dce3e8;
            }
            &-left{
                width: 30%;
                box-sizing: border-box;
                height: 100%;
                .directory{
                    height: 100%;
                    overflow: auto;
                    padding-right: 15px;
                }
            }
            &-right{
                width: 70%;
                height: 100%;
                padding-left: 15px;
                box-sizing: border-box;
                .select-group{
                    height: calc(100% - 32px);
                    box-sizing: border-box;
                    overflow: auto;
                    &-item{
                        .res{
                            .res-img{
                                width:14px;
                                height:14px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
        .dialog-footer {
            @include flex(row, space-between, center);
            .left{
                @include flex(row, center, center);
                width: calc(100% - 244px);
                padding: 0 30px;
                box-sizing: border-box;
            }
            .right{
                width: 244px;
            }
        }
    }
    .schedule-item-addByInput{
        .add-res-form-content {
            display: block;
            padding: 10px;
            text-align: left;
            white-space: pre-wrap;
            width: 100%;
            box-sizing: border-box;
            min-height: 100px;
            border-radius: 4px;
        }
        .dialog-footer {
            @include flex(row, center, center);
        }
    }
    .schedule-item-titleSetting{
        .setting-item {
            margin-bottom: 20px;
            @include flex(row, flex-start, center);
            .left{
                width: 80px;
            }
            .right{
                @include flex(row, flex-start, center);
                width: calc(100% - 80px);
                .el-date-editor{
                    width: 50%;
                }
            }
        }
    }
}
.editPanel{
    width: 230px;
    z-index: 2203;
    position: fixed;
    top: 5px;
    background:#ffffff;
    box-shadow:0px 2px 20px 0px rgba(70,79,85,0.2);
    border:1px solid #e6ebee;
    border-radius: 10px;
    overflow: hidden;
    &.inLeft{
        right: 100%;
    }
    &.inRight{
        left: 100%;
    }
    .editPanel-header,.color-pick-header,.day-pick-header{
        @include flex(row, space-between, center);
        width: 100%;
        box-sizing: border-box;
        padding: 5px 10px;
        background:#f8f9fa;
        height:34px;
        border-bottom:1px solid #eaedf0;
        .title{
            color:rgba(52,56,62,1);
            font-weight:600;
        }
    }
    .editPanel-header{
        justify-content: center;
        font-size: 16px;
    }
    .color-pick{
        &-header{
            border-bottom:1px solid #eaedf0;
            font-size:14px;
            .color-value{
                color:rgba(52,56,62,1);
            }
        }
        &-predefine{
            width: 100%;
            box-sizing: border-box;
            padding: 5px 10px;
            .predefine__colors{
                @include flex(row, flex-start, center);
                flex-wrap: wrap;
                .predefine__color-selector{
                    border-radius: 50%;
                    border:1px solid rgba(238,242,245,1);
                    padding: 4px;
                    margin: 7px 8px;
                    .predefine__color-selector-inner{
                        border-radius: 50%;
                        width: 16px;
                        height: 16px;
                    }
                }
                .color-selected{
                    background: rgba(238, 242, 245, 1);
                    i{
                        color: #fff;
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
    .day-pick{
        &-header{
            border-bottom:1px solid #eaedf0;
            font-size:14px;
        }
        &-predefine{
            width: 100%;
            box-sizing: border-box;
            padding: 5px 5px;
            .predefine__days{
                @include flex(row, flex-start, center);
                flex-wrap: wrap;
                .predefine__day-selector{
                    padding: 4px;
                    margin: 0 5px;
                }
            }
        }
    }
    .editPanel-footer{
        height:44px;
        padding: 0 10px;
        @include flex(row, space-between, center);
        background:#f8f9fa;
        border-top:1px solid #eaedf0;
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>

<style lang="scss">
.container .schedule-item{
    .schedule-item-addByRes,
    .schedule-item-addByInput,
    .schedule-item-titleSetting{
        .el-dialog{
            min-width: 400px;
            border-radius: 5px;
            overflow: hidden;
            .el-dialog__header,.el-dialog__footer{
                background:rgba(248,249,250,1);
            }
            .el-dialog__header{
                border-bottom:1px solid rgba(234,237,240,1);
            }
            .el-dialog__footer{
                padding: 10px;
                border-top:1px solid rgba(234,237,240,1);
            }
        }
    }
    &-addByRes{
        .el-dialog{
            width: 60%;
            max-height: 65vh;
            height: 510px;
            .el-dialog__body{
                height: calc(100% - 110px);
                box-sizing: border-box;
                padding: 15px;
            }
            .el-dialog__footer{
                position: relative;
                bottom: 0;
            }
        }
    }
    &-titleSetting{
        .el-dialog{
            width: 40%;
        }
    }
    &-addByInput{
        .el-dialog{
            width: 40%;
            .el-dialog__body{
                padding: 15px 10px;
            }
        }
    }
}
</style>
